<template>
  <div class="violations-modal__event-tip">
    <div class="violations-modal__event-tip__mark" />
    <r-text>
      Поля со знаком были импортированы из Редактора ОДД
    </r-text>
  </div>
</template>

<script>
export default {}
</script>

<style lang="scss">
.violations-modal__event-tip {
  display: grid;
  grid-auto-flow: column;
  grid-gap: 0.25rem;
  justify-content: start;
  align-content: start;
  align-items: center;

  &__mark {
    width: 8px;
    height: 8px;
    border: 1px solid var(--white_white);
    border-radius: 50%;
    overflow: hidden;
    background-color: var(--accent_primary);
  }
}
</style>
